import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Card, Collapse, CollapseProps } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import GoogleLogin from 'react-google-login';
import FacebookLogin, {RenderProps} from 'react-facebook-login/dist/facebook-login-render-props'
import { FacebookLoginButton,GoogleLoginButton } from "react-social-login-buttons";
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';


const items: CollapseProps['items'] = [
  {
    key: '1',
    label: 'I belive Greta',
    children: <p></p>,
  },
  {
    key: '2',
    label: 'The news',
    children: <p></p>,
  },
  {
    key: '3',
    label: 'The protests are undoubtedly justified',
    children: <p>
      <Paragraph>
        <blockquote style={{fontStyle:'italic'}}>Free Palestine. Ceasefire now. Keep the world clean.</blockquote>
        <blockquote style={{fontStyle:'italic'}}>So many people go out on the streets, best friends, colleagues.</blockquote>
        Our beliefs might be right, wrong, or somewhere in between.
      </Paragraph>
    </p>,
  },
  {
    key: '4',
    label: "Israel lies",
    children: <p>
      <Paragraph>
      </Paragraph>
    </p>,
  },
  {
    key: '5',
    label: 'Ceasefire now',
    children: <div>
      <p>Someone comes to your home once every few days, tries to shoot you through your glass door. Your glass door is shielded, so the bullets don’t come through. Family thinks you should not react, because nothing happens. Eventually one day this guy breaks your front door, puts a bullet in your child’s head, takes your second one, and burns your house. Would you run after him, or do nothing hoping he will be polite and generously decide to bring your daughter back? If you choose the second option that’s a wrong answer 🙂</p>
      <p>Now, you might be asking, but why would he decide to act violently on the first place? That's a good question. Why did the scorpion sting the frog? I think the answer is the same.</p>
    </div>,
  },
  {
    key: '6',
    label: 'Stop the genocide',
    children: <p></p>,
  },
  {
    key: '7',
    label: "Occupation",
    children: <p></p>,
  },
  {
    key: '8',
    label: "Pictures from Gaza",
    children: <p></p>,
  },
  {
    key: '9',
    label: "This has never happened",
    children: <p></p>,
  },
]

const cards: {
  title: string
  text?: string  
}[] = [
  {
    title: "I belive Greta",
    text: "aasdasd aasdasd aasdasd aasdasd aasdasd aasdasd aasdasd aasdasd aasdasd aasdasd "
  },
  {
    title: "I belive Greta"
  },
  {
    title: "I belive Greta"
  },
]

function Head() {
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/`; 
    navigate(path);
  }

  return (
    <div style={{display:'flex', paddingTop: 8 }}>
      <div style={{flexGrow: 1}}></div>
      <div style={{width: 150, textAlign: 'center'}}><span style={{textDecoration: 'underline', cursor:'pointer'}} onClick={() => navigate('/')}>Upvote for Israel</span></div>
      <div style={{width: 150, textAlign: 'center'}}><span style={{textDecoration: 'underline dotted', cursor:'pointer'}} onClick={() => navigate('/fuck')}>Fuck Israel</span></div>
      <div style={{flexGrow: 1}}></div>
    </div>
  );
}

function SupportPage() {
  const [loggedIn, setLoggedIn] = useState(false);

  const onFacebookLogin = (response:any) => {
    if (response.accessToken) {
      setLoggedIn(true);
    }
  };

  const onGoogleLogin = (response:any) => {
    if (response.accessToken) {
      setLoggedIn(true);
    }
  };

  return (
    <div style={{display: 'flex', flexDirection:'column', height: '100%'}}>
      {/* <Head /> */}
      <div style={{backgroundColor: "#d9f8e0", height: '2.5rem', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <div style={{textAlign: 'center', fontWeight: 500, fontSize: 22, fontFamily: 'MyWebFont'}}>
        jews.support
        </div>
      </div>
      <div style={{backgroundColor: '##1fc8db', backgroundImage: 'linear-gradient(140deg, #EADEDB 0%, #BC70A4 50%, #BFD641 75%)', flexGrow: 1,display: 'flex',  flexDirection:'column'}}>
        <div style={{padding: 20, flexGrow: 1, display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
          <div style={{fontSize: 50, fontWeight: 'bold', color: 'white'}}>12,349,759 upvotes</div>
          <div style={{paddingTop: '1rem', fontSize: 20, color: 'white', fontWeight: '500' }}>I support the Jews & the State of Israel.</div>
          <div style={{paddingTop: '1rem', display: 'flex', columnGap: '10px'}}>
            <img className="flag" src="https://flagcdn.com/w40/ua.png" srcSet="https://flagcdn.com/w80/ua.png 2x" width="24" alt="Ukraine" />
            <img className="flag" src="https://flagcdn.com/w40/il.png" srcSet="https://flagcdn.com/w80/il.png 2x" width="24" alt="Israel" />
            <img className="flag" src="https://flagcdn.com/w40/hu.png" srcSet="https://flagcdn.com/w80/hu.png 2x" width="24" alt="Hungary" />
            <img className="flag" src="https://flagcdn.com/w40/mx.png" srcSet="https://flagcdn.com/w80/mx.png 2x" width="24" alt="Mexico" />
            <img className="flag" src="https://flagcdn.com/w40/nz.png" srcSet="https://flagcdn.com/w80/nz.png 2x" width="24" alt="New Zealand" />
            <img className="flag" src="https://flagcdn.com/w40/pl.png" srcSet="https://flagcdn.com/w80/pl.png 2x" width="24" alt="Poland" />
          </div>
        </div>
        <div style={{textAlign: 'center', margin: 30}}>
          <div style={{width: 220, display: 'inline-block', margin: 2}}>
            <FacebookLogin
              appId="1088597931155576"
              autoLoad={false}
              fields="name,email,picture"
              callback={onFacebookLogin}
              render={(props: RenderProps) => <FacebookLoginButton onClick={props.onClick} text="Upvote with Facebook" style={{fontSize: 15, height: 40}} />}
            />
            </div>
          <div style={{width: 220, display: 'inline-block'}}>
            <GoogleLogin
              clientId="your-google-client-id"
              buttonText="Login with Google"
              onSuccess={onGoogleLogin}
              onFailure={onGoogleLogin}
              cookiePolicy={'single_host_origin'}
              render={(props: { onClick: () => void, disabled?: boolean }) => <GoogleLoginButton onClick={props.onClick} text="Upvote with Google" style={{fontSize: 15, height: 40}} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function FuckPage() {
  return (
    <div>
      <Head />
      <div style={{paddingLeft: 20, marginTop: 20}}>Israel's actions are unacceptable. Here's why.</div>
      {/* {cards.map((card, i) => 
        <Card key={card.title} title={card.title} bordered={false} style={{ width: 300 }}>
          <p>{card.text}</p>
        </Card>
      )} */}
      <div style={{paddingLeft: 30,paddingRight: 30,paddingBottom: 30, paddingTop:22}}>
        {items?.map((item: any) => 
          <div style={{padding: 5}}>
            <Collapse items={[item]} />
          </div>)}
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/fuck" element={<FuckPage />} />
        <Route path="/" element={<SupportPage />} />
      </Routes>
    </Router>
  );
}

export default App;
